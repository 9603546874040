import React from "react"
import { graphql } from "gatsby"
import PageRoot from "../components/PageRoot"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { Col, Row, Space } from "antd"
import ReadOnlyQuill from "../components/ReadOnlyQuill"
import withOwnershipCheck from "../components/withOwnershipCheck"
import { useTranslation } from "react-i18next"
import {
  devLog,
  getDownloadUrl,
  getImageUrl,
  renderCarouselItem,
} from "../utils"
import CertificationDetailHeader from "../components/CertificationDetailHeader"
import HeaderExtra from "../components/HeaderExtra"
import withCertificationState from "../components/withCertificationState"
import { CheckSquareOutlined } from "@ant-design/icons"
import "./CertificationDetailPage.less"
import AtsGrid from "../components/AtsGrid"
import moment from "moment"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"

const CertificationDetailHeaderWithCheck = withCertificationState(
  withOwnershipCheck(CertificationDetailHeader)
)

// t("label:releaseInfo-CERTIFICATION")
// t("label:releaseInfo-DIPLOMA")
// t("label:releaseInfo-MASTER")

const CertificationDetailPage = ({ data, pageContext }) => {
  devLog({ data, pageContext })

  const { t } = useTranslation()
  const certification = data.ats.certifications_by_pk

  const { seo } = certification

  const {
    type,
    description,
    landscape_locked,
    landscape_success,
    proTips,
    where_to_use,
    required_certifications,
    required_courses,
    trailer,
    trailer_poster,
    release_info,
    extra_info,
    authorities,
    exam,
    badge_title,
  } = certification

  devLog({ certification })

  const seoImage = getImageUrl(landscape_success, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  return (
    <PageRoot
      title={seo.title}
      description={seo.description}
      image={process.env.GATSBY_AUTH0_REDIRECT_URI + seoImage}
    >
      <div id="intro">
        <CertificationDetailHeaderWithCheck data={certification} />
      </div>
      <ContainerWithPadding align={"start"} size={"large"}>
        <Row gutter={26} className="data-video-container">
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <div id="description" className="row-info certification-spacer">
              <h2>{t("label:certificationDescription")}</h2>
              <hr />
              <ReadOnlyQuill value={description} />
              <HeaderExtra
                images={(authorities || []).map(item =>
                  getDownloadUrl(null, { fileId: item })
                )}
              />
            </div>
            {trailer && (
              <div id="trailer" className="row-info">
                <h2>{t("label:trailer")}</h2>
                <hr />
                <video
                  poster={
                    trailer_poster
                      ? getDownloadUrl(trailer_poster.s3_key)
                      : null
                  }
                  controls
                >
                  <source src={getDownloadUrl(trailer.s3_key)} />
                </video>
              </div>
            )}
            {release_info && (
              <div id="releaseInfo" className="row-info">
                <h2>{t(`label:releaseInfo-${type}`)}</h2>
                <hr />
                <ReadOnlyQuill value={release_info.description} />
                <Row gutter={[16, 16]}>
                  {release_info.pictures?.map(picture => (
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <img
                        className="certification-images"
                        src={getDownloadUrl(null, { fileId: picture })}
                        alt={picture}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            <div id="carryingOnMethod" className="row-info">
              <h2>{t("label:carryingOnMethod")}</h2>
              <hr />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("message:carryingOnMethodMessage", {
                    quizzesCount: exam?.sampleCount,
                    duration: moment.duration(exam?.duration).humanize(),
                    coolDown: moment.duration(exam?.coolDown).humanize(),
                    badgeTitle: badge_title,
                  }),
                }}
              />
            </div>

            {extra_info && (
              <div id="extraInfo" className="row-info">
                <h2>{t("label:extraInfo")}</h2>
                <hr />
                <ReadOnlyQuill value={extra_info} />
              </div>
            )}
            {where_to_use?.length > 0 && (
              <div id="whereToUse" className="row-info where-to-use-wrapper">
                <h2>{t("label:certificationWhereToUse")}</h2>
                <hr />
                <Row gutter={[16, 16]} className={"where-to-use-list"}>
                  {where_to_use.map((item, index) => (
                    <Col
                      span={12}
                      key={index}
                      className={"where-to-use-list-item"}
                    >
                      <Space>
                        <CheckSquareOutlined />
                        {item}
                      </Space>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            {required_certifications?.length > 0 && (
              <div id="requiredCertifications" className="row-info">
                <h2>{t("label:certificationRequiredCertifications")}</h2>
                <hr />
                <Row>
                  <Col span={24}>
                    <AtsGrid
                      dataSource={required_certifications.map(
                        ({ certification }) => certification
                      )}
                      renderItem={renderCarouselItem}
                      title={null}
                    />
                  </Col>
                </Row>
              </div>
            )}
            {required_courses?.length > 0 && (
              <div id="requiredCourses" className="row-info">
                <h2>{t("label:certificationRequiredCourses")}</h2>
                <hr />
                <AtsGrid
                  dataSource={required_courses.map(({ course }) => course)}
                  renderItem={renderCarouselItem}
                  title={null}
                />
              </div>
            )}
          </Col>
        </Row>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getCertification($certificationId: ats_uuid!) {
    ats {
      certifications_by_pk(id: $certificationId) {
        ...PreviewCardCertification
        description
        proTips
        where_to_use
        trailer {
          s3_key
        }
        trailer_poster {
          s3_key
        }
        extra_info
        release_info
        authorities
        exam {
          duration
          coolDown
          sampleCount
        }
        seo {
          title
          description
          slug
        }
        wallpaper {
          ...Wallpaper
        }
        requirements {
          required_item_id
        }
        required_certifications: requirements(
          where: { required_item_type: { _eq: CERTIFICATION } }
        ) {
          certification {
            ...PreviewCardCertification
          }
        }
        required_courses: requirements(
          where: { required_item_type: { _eq: COURSE } }
        ) {
          course {
            ...PreviewCardCourse
          }
        }
      }
    }
  }
`

export default CertificationDetailPage
