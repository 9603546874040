import React from "react"
import "./CertificationDetailHeader.less"
import { Affix, Button, Col, Row, Skeleton, Space, Tag } from "antd"
import {
  FavoriteButton,
  PurchaseButton,
  RequireCertificationShippingButton,
  StartExamButton,
} from "./Buttons"
import { useImageColorDetector } from "../hooks/useImageColorDetector"
import { useTranslation } from "react-i18next"
import { Link, navigate } from "gatsby"
import { devLog, translatePath, uiHandleError } from "../utils"
import Countdown from "./Countdown"
import { CertificationType } from "./Labels"
import useCertification from "../hooks/useCertification"
import moment from "moment"
import InstallmentWidget from "./payment/InstallmentWidget"

const CertificationDetailHeader = ({
  data,
  loading,
  canPurchase,
  hasRequirements,
  onToggleFavorite,
  onPurchase,
  showFavoriteButton,
  isFavorite,
  canTakeExam,
  canRequireShipping,
  passed,
  shipped,
  shippingRequired,
  lockedDuration,
}) => {
  const { t } = useTranslation()
  const {
    wallpaper,
    title,
    price,
    special_price,
    type,
    id,
    seo,
    required_certifications,
  } = data
  const { requireShipping } = useCertification()

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src,
  })

  devLog({ canRequireShipping, canTakeExam, passed, shipped })

  const handlePurchaseClick = () => {
    if (hasRequirements) {
      uiHandleError({
        message: t("error:certificationHasRequirements"),
        action: () => {
          let element
          if (required_certifications?.length > 0) {
            element = document.getElementById("requiredCertifications")
          } else {
            element = document.getElementById("requiredCourses")
          }

          element.scrollIntoView()
        },
      })
    } else {
      onPurchase && onPurchase(data)
    }
  }

  return (
    <div
      className="content-detail-header certification-detail-header"
      style={{
        backgroundImage: `url(${wallpaper?.localFile?.childImageSharp.fixed.src})`,
      }}
    >
      <div className={`recap-wrapper content ${isDark ? "dark" : "light"}`}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <CertificationType type={type} />
            <h1 className="title">{title}</h1>
            <br />
            <div>
              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  <Space align={"start"} wrap>
                    <Button className="yellowsp">
                      <Link to={`/certification/review-exam?id=${id}`}>
                        {t("button:reviewExam")}
                      </Link>
                    </Button>
                    {canPurchase && (
                      <PurchaseButton
                        price={price}
                        specialPrice={special_price}
                        onClick={handlePurchaseClick}
                      />
                    )}
                    {canTakeExam && (
                      <StartExamButton
                        onClick={() => {
                          navigate(
                            `/${translatePath("certification")}/${
                              seo.slug
                            }/${translatePath("exam")}`
                          )
                        }}
                      />
                    )}
                    {lockedDuration > 0 && (
                      <>
                        <StartExamButton
                          onClick={() => {
                            uiHandleError({
                              message: t("error:examLocked", {
                                duration: moment
                                  .duration(lockedDuration)
                                  .humanize(),
                              }),
                            })
                          }}
                        />
                      </>
                    )}
                    {canRequireShipping && (
                      <RequireCertificationShippingButton
                        onClick={() => {
                          navigate(`/certification/require-shipping?id=${id}`)
                        }}
                      />
                    )}
                    {showFavoriteButton && (
                      <FavoriteButton
                        onClick={() => {
                          onToggleFavorite && onToggleFavorite(data)
                        }}
                        isFavorite={isFavorite}
                      />
                    )}
                  </Space>
                  <br />
                  <br />
                  <Space>
                    {passed && <Tag color="blue">{t("label:examPassed")}</Tag>}
                    {shippingRequired && !shipped && (
                      <Tag color="blue">{t("label:shippingRequired")}</Tag>
                    )}
                    {shipped && <Tag color="blue">{t("label:shipped")}</Tag>}
                    {lockedDuration > 0 && (
                      <p>
                        <span className="count-down-message">
                          {t("message:youCanTakeExamAfter")}{" "}
                        </span>
                        <Countdown duration={lockedDuration} size={106} />
                      </p>
                    )}
                  </Space>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="detail-wrapper">
        <Row gutter={60}>
          <Col xs={24} sm={24} md={20} lg={16} xl={16} />
          <Col sm={24} xs={24} md={24} lg={8} xl={8} xxl={8}>
            <Affix className="affix-wrapper">
              <div className="side-nav-wrapper">
                <div className="single-action">
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <Space direction="vertical">
                        {canPurchase && (
                          <>
                            <PurchaseButton
                              price={price}
                              specialPrice={special_price}
                              onClick={handlePurchaseClick}
                            />
                            <InstallmentWidget
                              onPurchaseClick={handlePurchaseClick}
                              price={price}
                            />
                          </>
                        )}
                        {canTakeExam && (
                          <StartExamButton
                            onClick={() => {
                              navigate(
                                `${translatePath("/certification")}/${
                                  seo.slug
                                }/${translatePath("exam")}`
                              )
                            }}
                          />
                        )}
                        {lockedDuration > 0 && (
                          <StartExamButton
                            onClick={() => {
                              uiHandleError({
                                message: t("error:examLocked", {
                                  duration: moment
                                    .duration(lockedDuration)
                                    .humanize(),
                                }),
                              })
                            }}
                          />
                        )}
                        <div className="certification-feature-wrapper">
                          <Space direction="vertical">
                            <div>
                              <span className="each-certification">
                                {t("message:MultipleAnswerTest")}
                              </span>
                              <span className="each-certification">
                                {t("message:ChanceToRepeatExam")}
                              </span>
                              <span className="each-certification">
                                {t("message:TechnicalAssistance")}
                              </span>
                              <span className="each-certification">
                                {t("message:ProfessionalFormation")}
                              </span>
                            </div>
                          </Space>
                        </div>
                      </Space>
                    </>
                  )}
                </div>
              </div>
            </Affix>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CertificationDetailHeader
